<template>
  <b-modal
    ref="refModal"
    id="taxi-jura-card-form-to-add-money-modal"
    :title="`JURA Card (£${juraCardData ? juraCardData.balance : 0})`"
    ok-title="Add"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    @ok="handleOk"
    @hidden="resetCard"
  >
    <validation-observer ref="addMoneyToJuraCardForm">
      <b-form
        id="add-new-taxi-jura-card"
        @submit.prevent="validateForm()"
      >
        <validation-provider
          #default="{ errors }"
          name="Card Number"
          rules="required"
        >
          <b-form-group
            label="Card Number"
            label-for="card-number"
          >
            <b-form-input
              id="card-number"
              v-model="juraCardData.cardNfcNum"
              placeholder="Card Number"
              readonly
              :state="errors.length > 0 ? false : null"
              name="card-number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Amount"
          rules="required"
        >
          <b-form-group
            label="Amount"
            label-for="amount"
          >
            <b-input-group
              prepend="£"
              class="input-group-merge"
            >
              <b-form-input
                id="amount"
                v-model="amountToAdd"
                type="number"
                step=".01"
                max="1000000"
                min="1"
                placeholder="Amount"
                :state="errors.length > 0 ? false : null"
                name="amount"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import useTaxiJuraCard from '@/modules/taxi/composables/useTaxiJuraCard';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

export default {
  props: {
    juraCard: {
      type: Object,
    },
    taxiId: {
      type: Number,
    },
  },
  setup(props, ctx) {
    const { addCreditToJuraCard } = useTaxiJuraCard();
    const addMoneyToJuraCardForm = ref(null);
    const refModal = ref(null);
    const juraCardData = ref({ ...props.juraCard });
    const amountToAdd = ref(0);
    const toast = useToast();

    const handleOk = (modalEvt) => {
      modalEvt.preventDefault();
      validateForm();
    };

    const resetCard = () => {
      juraCardData.value = { ...props.juraCard };
      amountToAdd.value = 0;
    };

    const validateForm = () => {
      if (amountToAdd.value <= 0) return;

      addMoneyToJuraCardForm.value.validate().then(async (valid) => {
        if (!valid) return;

        const response = await addCreditToJuraCard(props.taxiId, juraCardData.value.cardNfcNum, amountToAdd.value);

        if (response !== null) {
          toast.success({
            component: ToastificationContent,
            props: {
              title: 'Transaction created successfully!',
              icon: 'CheckIcon',
            },
          });
          
        ctx.emit('form-submitted');
          refModal.value.hide();
        }
      });
    };

    return {
      // Reactive
      addMoneyToJuraCardForm,
      refModal,
      juraCardData,
      amountToAdd,

      // Method
      handleOk,
      resetCard,

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BInputGroup,
    BInputGroupPrepend,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-taxi-jura-card {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
